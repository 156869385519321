import { ref } from 'vue'
const deleteModalOpen = ref<boolean>(false)
const valueToDeleteObject = ref<{}>({})

export const useModal = () => {
  const openDeleteModal = () => {
    deleteModalOpen.value = true
  }

  const clearDeleteModal = () => {
    deleteModalOpen.value = false
  }

  return {
    deleteModalOpen,
    openDeleteModal,
    valueToDeleteObject,
    clearDeleteModal,
  }
}
