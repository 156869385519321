import { setupLayouts } from 'virtual:generated-layouts'
import generatedRoutes from 'virtual:generated-pages'
import { createRouter, createWebHistory } from 'vue-router'
import { useAuth } from '@/composables/useAuth'
import { useUserStore } from '@/store/useUserStore'
import { useLocale } from '@/composables/useLocale'
import i18n from '@/plugins/i18n-plugin'
import { useFathom } from '~/composables/fathom/useFathom'
import { storeToRefs } from 'pinia'
import { useModels } from '~/composables/api/useModels'
import { useActiveMenu } from '~/composables/useActiveMenu'
import { useFeatureToggles } from '~/composables/features/useFeatureToggles'

const { load } = useFathom()
const { capitalizeFirstLetter } = useStringUtils()
const routes = setupLayouts(generatedRoutes)
// @ts-ignore
const router = createRouter({
  routes,
  history: createWebHistory(),
})

const isViewportSmall = () => window.innerWidth < 800

const excludedSlugs = [
  'rating-estimation',
  'language-detection',
  'email-addresses-extraction',
  'locations-extraction',
  'monetary-values-extraction',
  'names-extraction',
  'phone-numbers-extraction',
  'products-extraction',
  'touchpoints-extraction',
  'satisfaction-factors',
  'advanced-translation',
]

// use this list also to allow small screens
const unauthenticatedRoutes = [
  'auth-login',
  'auth-type',
  'auth-register',
  'auth-reset-password',
  'auth-new-password',
  'auth-verify-email',
  'auth-oauth-slug-callback',
  'small-screen-notice',
  'splashscreen',
]
const { models } = useModels()

const currentModelUrl = ref(null)
const getMappedRoutedTitleValue = (key) => {
  // some names are override in page but can create weird bug with tabs
  const mapping = {
    login: i18n.global.t('Login'),
    register: i18n.global.t('Register'),
    insights: i18n.global.t('Insights'),
    developer: i18n.global.t('Developer'),
    models: i18n.global.t('Models'),
    profile: i18n.global.t('Account'),
    model: i18n.global.t('Model', [
      currentModelUrl.value && currentModelUrl.value.title
        ? ` : ${currentModelUrl.value.title}`
        : null,
    ]),
    home: i18n.global.t('Home'),
    'auth-new-password': i18n.global.t('Reset'),
    'auth-reset-password': i18n.global.t('Reset'),
    'auth-verify-email': i18n.global.t('Verification'),
  }
  return mapping[key] || key
}

router.beforeEach(async (to) => {
  const { locale } = useLocale()
  const { handleActiveSelectionMenu } = useActiveMenu()
  const language = to.params.lang
  const store = useUserStore()
  const { userLocalePreference } = storeToRefs(store)
  const { haveInsightsAccessOnly } = useFeatureToggles()
  if (language) {
    if (language === 'en' || language === 'fr') {
      userLocalePreference.value = language
      locale.value = i18n.global.locale.value
      i18n.global.locale.value = to.params.lang
    }
  }
  // @ts-ignore
  handleActiveSelectionMenu(to.name)

  currentModelUrl.value = models.value
    ? models.value.find((model) => model.slug === to.params.slug)
    : null
  // @ts-ignore
  const name = to.name
    ? getMappedRoutedTitleValue(
        to.name === 'auth-type' ? to.params.type : to.name
      )
    : ''
  document.title = name
    ? `${capitalizeFirstLetter(name)} — Diabolocom AI`
    : 'Diabolocom AI'
  load()

  const { authenticated } = useAuth()

  // @ts-ignore
  const headingToLogin = unauthenticatedRoutes.includes(to.name)

  if (
    // make sure the user is authenticated
    !authenticated.value &&
    // ❗️ Avoid an infinite redirect
    !headingToLogin
  ) {
    // redirect the user to the login page
    useUserStore()
    return {
      path: `/${locale.value}/auth/login`,
      query: {
        redirect: to.path,
      },
    }
  }

  // same logic as previous guard, but for screen size
  if (
    // screen too small
    isViewportSmall() &&
    // ❗️ Avoid an infinite redirect
    !headingToLogin
  ) {
    return {
      path: `/${locale.value}/small-screen-notice`,
    }
  }

  // If we change viewport width, this allows to redirect notice page to home
  if (
    // screen not too small
    !isViewportSmall() &&
    to.name === 'small-screen-notice'
  ) {
    return {
      path: `/${locale.value}/home`,
    }
  }

  if (haveInsightsAccessOnly.value && authenticated.value) {
    if (
      to.name !== 'insights' &&
      !(to.name === 'profile' && to.query.tab === 'informations')
    ) {
      return { path: `/${locale.value}/insights` }
    }
  }

  if (
    authenticated.value &&
    headingToLogin &&
    to.name !== 'small-screen-notice' &&
    to.name !== 'auth-verify-email'
  ) {
    return haveInsightsAccessOnly.value
      ? { path: `/${locale.value}/insights` }
      : { path: `/${locale.value}/home` }
  }

  if (to.matched.length === 0) {
    console.log('no route matched')
    // Redirect to the root path if no route matched
    return { path: `/${locale.value}/home` }
  }
  //@ts-ignore
  if (to?.params?.slug && excludedSlugs.includes(to.params.slug)) {
    return { path: `/${locale.value}/home` }
  }
})

export { router }
